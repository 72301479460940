import React, { useState, useEffect } from 'react';
import { StoreProvider } from './store/store';
import { IntlProvider } from 'react-intl';
import Layout from './components/Layout';
import Home from './components/pages/Home';

function App() {
  const [locale, setLocale] = useState(false);

  const fetchLocale = async () => {
    const response = await fetch('locale/pl_PL/ui.json').then(r => r.json());
    setLocale(response);
  };

  useEffect(() => {
    fetchLocale();
  }, []);

  return (
    locale && (
      <IntlProvider defaultLocale={'pl'} locale={'pl'} messages={locale}>
        <StoreProvider>
          <Layout>
            <Home onChange={() => false} />
          </Layout>
        </StoreProvider>
      </IntlProvider>
    )
  );
}

export default App;
