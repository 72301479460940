import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import styled from 'styled-components';
import Loading from '../../atoms/Loading';
import useWindowSize from '../../../hooks/useWindowSize';
import sygnet from '../../../assets/sygnet-appartme-fiolet.svg';
import mobileBg from '../../../assets/mobile-bg.jpg';
import FormattedMessage from 'react-intl/lib/src/components/message';

const Room = lazy(() => import('../../organisms/Room'));
const Phone = lazy(() => import('../../organisms/Phone'));
const Statuses = lazy(() => import('../../organisms/Statuses'));
const Buttons = lazy(() => import('../../organisms/Buttons'));

const StyledHome = styled.div`
  ${tw`grid grid-cols-7`}
  & {
  }
`;

const StyledBrowser = styled.div`
  ${tw`w-full h-full mx-auto bg-center bg-cover box-border`}
  margin: auto;
  padding: 60px 0px;
  background-image: url(${mobileBg});
  img {
    ${tw`mx-auto text-center block`}
    width: 100px;
  }
  h2 {
    ${tw`mx-auto text-center mt-8 leading-normal`}
    color: #6a22bb;
    max-width: 600px;
  }
`;
const StyledH2 = styled.h2`
  ${tw`text-gray-500 text-4xl`};
  font-family: 'Poppins', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
`;

const HomeCol = styled.div`
  ${tw`col-span-5`};
`;
const PhoneCol = styled.div`
  ${tw`col-span-2`};
`;
const ButtonsCol = styled.div`
  ${tw`col-span-2 pt-2`};
`;

const isDesktop = width => width >= 1110;

const Home = () => {
  const [width] = useWindowSize();
  return isDesktop(width) ? (
    <StyledHome>
      <HomeCol>
        <StyledH2>
          <FormattedMessage id="header.flat" />
          <br />
          <FormattedMessage id="header.flat.with" />
        </StyledH2>
        <Suspense fallback={<Loading />}>
          <Room />
        </Suspense>
      </HomeCol>
      <PhoneCol>
        <StyledH2>
          <FormattedMessage id="header.appartme" />
        </StyledH2>{' '}
        <Suspense fallback={<Loading />}>
          <Phone />
        </Suspense>
      </PhoneCol>
      <Suspense fallback={<Loading />}>
        <Statuses>
          <StyledH2 style={{ color: 'transparent' }}>&nbsp;</StyledH2>
        </Statuses>
      </Suspense>
      <ButtonsCol>
        <StyledH2>
          <FormattedMessage id="header.motel" />
        </StyledH2>
        <Suspense fallback={<Loading />}>
          <Buttons />
        </Suspense>
      </ButtonsCol>
    </StyledHome>
  ) : (
    <StyledBrowser>
      <img src={sygnet} alt="" />
      <StyledH2>
        <FormattedMessage id={'browser.only'} />
      </StyledH2>
    </StyledBrowser>
  );
};

Home.propTypes = {
  state: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default Home;
